<!--
 * @Author: ChaiHongJun
 * @Date: 2021-06-02 14:22:35
 * @LastEditors: ChaiHongJun
 * @LastEditTime: 2021-11-26 16:05:37
 * @version: 
 * @Description: 分类页（首页）模板
-->
<template>
  <section class="container">
    <div class="content-wrap">
      <div class="content">
        <Banner :banners="banners" :loadingBanner="loadingBanner"></Banner>
        <!-- 判断是首页还是栏目页  -->
        <div class="title" v-if="isHome">
          <h3>最新发布</h3>
          <div class="more">
            <a href="/">热门标签</a>

            <a href="#" target="_blank" title="华美整形">测试内容</a>
            <a href="#" target="_blank" title="测试内容">测试内容</a>
          </div>
        </div>
        <div class="catleader" v-else>
          <h1>{{ cateName }}</h1>
        </div>
        <a-skeleton
          :loading="loadingList"
          :active="loadingList"
          :paragraph="{ rows: 10 }"
        >
          <template v-if="listTotal > 0">
            <article
              class="excerpt excerpt-1"
              v-for="(item, index) of articleList"
              :key="index"
            >
              <a class="focus" :title="item.title" @click="toDetail(item.id)">
                <img
                  :alt="item.title"
                  :src="'https://assets.hzwlb.org' + item.thumb"
                  class="thumb img-responsive"
                  style="display: inline"
                />
              </a>
              <header>
                <a
                  class="cat"
                  :title="item.categoryName"
                  @click="toRoute(item.cid)"
                  >{{ item.categoryName }}<i></i
                ></a>
                <h2>
                  <a :title="item.title" @click="toDetail(item.id)">{{
                    item.title
                  }}</a>
                </h2>
              </header>
              <p class="meta">
                <time><i class="fa fa-clock-o"></i>{{ item.publish_time }}</time
                ><span class="pv"
                  ><i class="fa fa-eye"></i>阅读({{ item.reading }})</span
                >
              </p>
              <p class="note">
                <a href="/news/11/236.html" :title="item.title">
                  {{ description(item.introduction) }}...
                </a>
              </p>
            </article>
          </template>
          <div class="panel-body" v-else>暂时没有找到</div>
        </a-skeleton>
        <!-- 分页 -->
        <div class="cl pd-5 bg-1 bk-gray mt-20">
          <el-pagination
            background
            class="pagination"
            layout="prev, pager, next,total"
            :total="listTotal"
            :page-size="limit"
            :page-count="pageCount"
            :current-page="currentPage"
            @size-change="hanldeSizeChange"
            @current-change="handleCurrentChange"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <div class="sidebar">
      <a-skeleton
        :loading="loadingSticky"
        :active="loadingSticky"
        :paragraph="{ rows: 5 }"
      >
        <Sidebar siderName="置顶推荐" :dataList="Sticky"></Sidebar>
      </a-skeleton>

      <a-skeleton
        :loading="loadingRand"
        :active="loadingRand"
        :paragraph="{ rows: 5 }"
      >
        <Sidebar siderName="随机推荐" :dataList="Rand"></Sidebar
      ></a-skeleton>
    </div>
  </section>
</template>
<script>
import Banner from "@/components/category/banner/Index.vue";
import Sidebar from "@/components/common/sidebar/Index.vue";
import { Message } from "element-ui";
import {
  getBanner,
  getArticleList,
  getStickyList,
  getRandList,
} from "@/request/index.js";
import bus from "@/bus/index.js";
export default {
  name: "Category",
  components: { Banner, Sidebar },
  data() {
    return {
      isHome: true, //首页或者是栏目页 ,默认首页
      banners: [],
      loadingList: true,
      loadingBanner: true,
      loadingSticky: true,
      loadingRand: true,
      articleList: [],
      maxID: 0,
      minID: 0,
      limit: 4,
      currentPage: 1,
      listTotal: 0,
      cateName: "主页",
      cateIDsidebar: 0,
      Sticky: [],
      Rand: [],
    };
  },
  computed: {
    offset() {
      return (this.currentPage - 1) * this.limit;
    },
    description() {
      return (desc) => {
        return desc.substring(0, 160);
      };
    },
    pageCount() {
      return Math.ceil(this.listTotal / this.limit);
    },
  },
  methods: {
    //进入栏目
    toRoute(id) {
      this.$router.push({ name: "Category", params: { id } });
    },
    //进入文章详情
    toDetail(id) {
      // console.log("文章ID：" + id);
      this.$router.push({ name: "Detail", params: { id } });
    },
    hanldeSizeChange(size) {
      this.limit = size;
      // 请求数据
      this.loadingList = true;
      if (this.$route.params.id) {
        getArticleList(this.$route.params.id, this.limit, this.offset)
          .then((res) => {
            this.articleList = res.data;
            this.listTotal = res.count;
            // console.log("res");
            // console.log(res);
            this.loadingList = false;
          })
          .catch((err) => {
            console.log("err" + err);
            Message.error("hanldeSizeChange" + err);
            this.loadingList = false;
          });
      } else {
        getArticleList(0, this.limit, this.offset)
          .then((res) => {
            this.articleList = res.data;
            this.listTotal = res.count;
            // console.log("res");
            // console.log(res);
            this.loadingList = false;
          })
          .catch((err) => {
            console.log("err" + err);
            Message.error("hanldeSizeChange indexPage" + err);
            this.loadingList = false;
          });
      }
    },
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage;
      console.log("当前页码：" + this.currentPage);
      //  点击翻页/页码后 请求数据
      this.loadingList = true;
      if (this.$route.params.id) {
        getArticleList(this.$route.params.id, this.limit, this.offset)
          .then((res) => {
            this.articleList = res.data;
            this.listTotal = res.count;
            // console.log("res");
            // console.log(res);
            this.loadingList = false;
          })
          .catch((err) => {
            console.log("err" + err);
            Message.error("handleCurrentChange" + err);
            this.loadingList = false;
          });
      } else {
        this.loadingList = true;
        getArticleList(0, this.limit, this.offset)
          .then((res) => {
            this.articleList = res.data;
            this.listTotal = res.count;
            // console.log("res");
            // console.log(res);
            this.loadingList = false;
          })
          .catch((err) => {
            console.log("err" + err);
            Message.error("handleCurrentChange indexPage" + err);
            this.loadingList = false;
          });
      }
    },
    // 当路由变化的时候，重载共享（首页和分类）组件 和侧栏组件
    reloadPage() {
      if (this.$route.path.indexOf("category") != -1) {
        //进入了栏目页，请求对应栏目页数据
        this.currentPage = 1;
        this.isHome = false;
        this.loadingList = true;
        getArticleList(this.$route.params.id, this.limit, this.offset)
          .then((res) => {
            this.articleList = res.data;
            this.listTotal = res.count;
            // console.log("res");
            // console.log(res);
            this.loadingList = false;
          })
          .catch((err) => {
            console.log("err" + err);
            Message.error("reloadPage loadingList" + err);
            this.loadingList = false;
          });

        if (this.isHome) {
          this.cateIDsidebar = 0;
        } else {
          this.cateIDsidebar = this.$route.params.id;
        }
        this.loadingSticky = true;
        getStickyList(this.cateIDsidebar, this.limit / 2, this.offset)
          .then((res) => {
            this.Sticky = res.data;
            this.loadingSticky = false;
          })
          .catch((err) => {
            console.log("err " + err);
            Message.error("reloadPage loadingSticky" + err);
            this.loadingSticky = false;
          });
        /* 加载侧栏 随机 内容 数据*/
        this.loadingRand = true;
        getRandList(this.cateIDsidebar, this.limit / 2, this.offset)
          .then((res) => {
            this.Rand = res.data;
            this.loadingRand = false;
          })
          .catch((err) => {
            console.log("err " + err);
            Message.error("reloadPage loadingRand" + err);
            this.loadingRand = false;
          });
      } else {
        //进入了首页，请求数据
        this.currentPage = 1;
        this.isHome = true;
        this.loadingList = true;
        // 第一次加载
        getArticleList(0, this.limit, this.offset)
          .then((res) => {
            this.articleList = res.data;
            this.listTotal = res.count;
            // console.log("res");
            // console.log(res);
            this.loadingList = false;
          })
          .catch((err) => {
            console.log("err" + err);
            Message.error("reloadPage loadingList indexPage" + err);
            this.loadingList = false;
          });

        if (this.isHome) {
          this.cateIDsidebar = 0;
        } else {
          this.cateIDsidebar = this.$route.params.id;
        }
        this.loadingSticky = true;
        getStickyList(this.cateIDsidebar, this.limit / 2, this.offset)
          .then((res) => {
            this.Sticky = res.data;
            this.loadingSticky = false;
          })
          .catch((err) => {
            console.log("err " + err);
            Message.error("reloadPage loadingSticky" + err);
            this.loadingSticky = false;
          });
        /* 加载侧栏 随机 内容 数据*/
        this.loadingRand = true;
        getRandList(this.cateIDsidebar, this.limit / 2, this.offset)
          .then((res) => {
            this.Rand = res.data;
            this.loadingRand = false;
          })
          .catch((err) => {
            console.log("err " + err);
            Message.error("reloadPage loadingRand" + err);
            this.loadingRand = false;
          });
      }
    },
  },
  watch: {
    $route: {
      handler: "reloadPage",
    },
  },
  created() {
    bus.$on("getCateName", (data) => {
      this.cateName = data;
    });

    // console.log("mounted:" + this.$route.params.id);
    // 组件初始化加载数据
    if (this.$route.params.id) {
      console.log("category 栏目页");
      this.isHome = false;
      this.loadingList = true;
      getArticleList(this.$route.params.id, this.limit, this.offset)
        .then((res) => {
          this.articleList = res.data;
          this.listTotal = res.count;
          this.listTotal = res.count;
          this.maxID = res.data[0].maxID;
          this.minID = res.data[0].minID;
          //   console.log("res");
          // console.log(res);
          this.loadingList = false;
        })
        .catch((err) => {
          console.log("err " + err);
          Message.error("loadingList created " + err);
          this.loadingList = false;
        });

      if (this.isHome) {
        this.cateIDsidebar = 0;
      } else {
        this.cateIDsidebar = this.$route.params.id;
      }
      this.loadingSticky = true;
      getStickyList(this.cateIDsidebar, this.limit / 2, this.offset)
        .then((res) => {
          this.Sticky = res.data;
          this.loadingSticky = false;
        })
        .catch((err) => {
          console.log("err " + err);
          Message.error("loadingSticky created" + err);
          this.loadingSticky = false;
        });
      /* 加载侧栏 随机 内容 数据*/
      this.loadingRand = true;
      getRandList(this.cateIDsidebar, this.limit / 2, this.offset)
        .then((res) => {
          this.Rand = res.data;
          this.loadingRand = false;
        })
        .catch((err) => {
          console.log("err " + err);
          Message.error("loadingRand created" + err);
          this.loadingRand = false;
        });
    } else {
      // 第一次打开首页
      // console.log("category 进入首页");
      this.isHome = true;
      this.loadingList = true;
      // 第一次加载
      getArticleList(0, this.limit, this.offset)
        .then((res) => {
          this.articleList = res.data;
          this.listTotal = res.count;
          // console.log("res");
          // console.log(res);
          this.loadingList = false;
        })
        .catch((err) => {
          console.log("err " + err);
          Message.error("articleList created" + err);
          this.loadingList = false;
        });

      if (this.isHome) {
        this.cateIDsidebar = 0;
      } else {
        this.cateIDsidebar = this.$route.params.id;
      }
      this.loadingSticky = true;
      getStickyList(this.cateIDsidebar, this.limit / 2, this.offset)
        .then((res) => {
          this.Sticky = res.data;
          this.loadingSticky = false;
        })
        .catch((err) => {
          console.log("err " + err);
          Message.error("loadingSticky created" + err);
          this.loadingSticky = false;
        });
      /* 加载侧栏 随机 内容 数据*/
      this.loadingRand = true;
      getRandList(this.cateIDsidebar, this.limit / 2, this.offset)
        .then((res) => {
          this.Rand = res.data;
          this.loadingRand = false;
        })
        .catch((err) => {
          console.log("err " + err);
          Message.error("loadingRand created" + err);
          this.loadingRand = false;
        });
    }
    /* 加载Banner数据 */
    getBanner()
      .then((res) => {
        this.banners = res.data;
        this.loadingBanner = false;
      })
      .catch((err) => {
        console.log("err " + err);
        Message.error("loadingBanner created" + err);
        this.loadingList = false;
      });
    /*  加载侧栏 置顶 内容 数据 */
    // 如果是首页
    // if (this.isHome) {
    //   this.cateIDsidebar = 0;
    // } else {
    //   this.cateIDsidebar = this.$route.params.id;
    // }
    // getStickyList(this.cateIDsidebar, this.limit / 2, this.offset)
    //   .then((res) => {
    //     this.Sticky = res.data;
    //     this.loadingSticky = false;
    //   })
    //   .catch((err) => {
    //     console.log("err " + err);
    //     this.loadingSticky = false;
    //   });
    // /* 加载侧栏 随机 内容 数据*/
    // getRandList(this.cateIDsidebar, this.limit / 2, this.offset)
    //   .then((res) => {
    //     this.Rand = res.data;
    //     this.loadingRand = false;
    //   })
    //   .catch((err) => {
    //     console.log("err " + err);
    //     this.loadingRand = false;
    //   });
  },
};
</script>
<style>
</style>